import { Card } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetUserById } from "apis/response";

import { formatDate } from "utils/utilDate";

import { formatPhone } from "utils/PhoneString";

import Table, { ColumnsType } from "antd/es/table";

function MemberDetails(): JSX.Element {
  const { id } = useParams();
  const [data, setData] = useState<IGetUserById[]>([]);

  const tableColumn: ColumnsType<IGetUserById> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "가족관계",
      dataIndex: "relation",
      key: "relation",
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "휴대전화번호",
      dataIndex: "phone",
      key: "phone",
      render: (text: string) => formatPhone(text),
    },
    {
      title: "가입일",
      dataIndex: "userCreatedAt",
      key: "userCreatedAt",
      render: (text) => formatDate(text),
    },
    {
      title: "프로필생성일",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text),
    },
    {
      title: "현재포인트",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
    },
    {
      title: "은행명",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "계좌번호",
      dataIndex: "bankAccount",
      key: "bankAccount",
    },
  ];

  useEffect(() => {
    Apis.getUserById(id)
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Table
          pagination={false}
          dataSource={data}
          rowKey={(record) => record.profileId}
          scroll={{ x: "max-content" }}
          columns={tableColumn}
        />
      </Card>
    </DashboardLayout>
  );
}

export default MemberDetails;
