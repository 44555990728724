import {
  IGetFilesUpload,
  getList,
  IGetUsers,
  IGetUserById,
  fileUploadTarget,
  fileUplaodType,
  WorkData,
  IDashBoardGraph,
  IGetAdministratorMy,
  ISetting,
} from "apis/response";
import { api } from "apis/axios";

const Apis = {
  /** 관리자 정보 수정(본인) */
  putAdministrator(option: any) {
    return api.put(`administrator`, option);
  },

  /** 관리자 마이 페이지; */
  getAdministratorMy() {
    return api.get<IGetAdministratorMy>(`administrator/my`);
  },

  /** 관리자 로그인 세션 체크 */
  getAuth() {
    return api.get("auth");
  },
  /** 관리자 로그인 */
  postAuth(option: any) {
    return api.post("auth", option);
  },
  /** 관리자 로그아웃 */
  deleteAuth() {
    return api.delete("auth");
  },

  /** 대시보드 오늘 */
  getDashBoardToday(option: any) {
    return api.get<
      getList<WorkData> & {
        totalMaxRecruitment: number;
        totalWorkApplyCount: number;
        totalCurrentRecruitment: number;
        totalGoWorkCount: number;
        totalWorkAbsenceCount: number;
        totalUnaccountedCount: number;
      }
    >(`dashboard/today`, { params: option });
  },
  /** 대시보드 내일 */
  getDashBoardTomorrow(option: any) {
    return api.get<
      getList<WorkData> & {
        totalMaxRecruitment: number;
        totalWorkApplyCount: number;
        totalCurrentRecruitment: number;
        totalGoWorkCount: number;
        totalWorkAbsenceCount: number;
      }
    >(`dashboard/tomorrow`, { params: option });
  },
  /** 대시보드 그래프 */
  getDashBoardGraph() {
    return api.get<IDashBoardGraph>(`dashboard/graph`);
  },

  /** Get pre-signed url */
  postFilesUpload({
    type,
    mimeType,
    imageUploadTarget,
    num = 1,
  }: {
    type: fileUplaodType;
    mimeType: string;
    imageUploadTarget: fileUploadTarget;
    num?: number;
  }) {
    return api.get<IGetFilesUpload[]>(`file/upload`, {
      params: {
        type,
        mimeType,
        imageUploadTarget,
        num,
      },
    });
  },

  /** 관리자 셋팅 조회 */
  getSettings() {
    return api.get<getList<ISetting>>(`setting`);
  },
  /** 관리자 셋팅 수정 */
  putSetting(id: string | number, option: any) {
    return api.put(`setting/${id}`, option);
  },

  /** 관리자 회원 조회 */
  getUsers(option: any) {
    return api.get<getList<IGetUsers>>(`user`, { params: { ...option } });
  },

  /** 관리자 회원 상세 조회 */
  getUserById(id: string | number) {
    return api.get<getList<IGetUserById>>(`user/${id}`);
  },
};

export default Apis;
